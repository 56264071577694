::-webkit-scrollbar {
    width: 5px;  /* Width of the scrollbar */
    height: 50px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #888;  /* Track color */
    
}

::-webkit-scrollbar-thumb {
    background: rgb(0, 105, 255);;  /* Scrollbar thumb color */
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(0, 105, 255);  /* Scrollbar thumb hover color */
}

.app{
    height: 100vh;
}