:root {
    --font-gilroy: "__gilroy_2ec981", "__gilroy_Fallback_2ec981", sans-serif;
    
}
body {
    font-family: var(--font-gilroy) !important;
    margin: 0;
}
.headerBox{
    border-bottom: 1px solid rgb(231, 237, 246);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.loginText{
    color:rgb(11, 53, 88) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    display: flex;
    justify-content: center;
}
.startedBtn{
    background-color: rgb(0, 107, 255) !important;
    color: white !important;
    padding: 10px 16px !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    margin-left: 1rem !important;
}
.backGroundImg{
    background-image: url('../assets/background.png');
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex !important;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    width: 100% mo !important;
    padding-top: 48px;
    padding-bottom: 48px;
}
.heading1{
    font-size: 4.25rem;
    line-height: 1.2;
    font-weight: 700;
    padding-bottom: 24px;
    white-space: pre-line;
    text-align: center;
    color: rgb(11, 53, 88);
    margin: 0 !important;
}
.heading2{
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 600;
    padding-bottom: 24px;
    white-space: pre-line;
    text-align: center;
    color: rgb(11, 53, 88);
    margin: 0 !important;
}
.paragraph1{
    max-width: 770px;
    padding-bottom: 24px;
    text-align: justify;
    color: rgb(71, 103, 136);
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: normal;
}
.paragraph2{
    max-width: 770px;
    text-align: center;
    color: rgb(71, 103, 136);
    line-height: 1.6;
    font-size: 0.875rem;
    font-weight: 500;
}
.socialIcons{
    display: flex;
    position: relative;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 16px 0 26px 0;
}
.socialIconsSize{
    width: 40px !important;
    height: 41px !important;
}
.orText{
    font-size: 0.875rem;
    color: rgb(71, 103, 136);
    position: relative;
    padding: 0px 16px;
    text-transform: uppercase;
}
.orText::before{
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    height: 1px;
    width: calc(100% + 230px) !important;
    background: rgb(229, 229, 229);
    z-index: 1;
}
.orText::after{
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: calc(100% + 230px) !important;
    background: rgb(229, 229, 229);
    z-index: 1;
    right: 100%;
}
.orDiv{
    display: flex;
    z-index: 1;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    padding-top: 16px;
    padding-bottom: 8px;
}
.socialText{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
}
.socialText .span1{
    text-align: left;
    color: rgb(0, 107, 255);
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 600;
}
.socialText .span2{
    color: rgb(11, 53, 88);
    font-size: 0.875rem;
    line-height: 1.4;
    font-weight: 500;
}


/* SideNav.css */
/* .leftArrow{
    position: absolute !important;
    top: 5.3rem;
    left: 3.5rem;
} */
.sidenavLogo{
    display: flex;
}
.createBtn{
    background-color: rgb(0, 107, 255) !important;
    color: white !important;
    padding: 10px 16px !important;
    font-size: 16px !important;
    border-radius: 40px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
    margin: 10px 0 !important;
    width: 100% !important;
}
.listItemIcon{
   min-width: 0 !important;
}

.listItemBtn{
    display: flex !important;
    gap: 20px !important;
}
.listItemBtn:hover{
    background-color: transparent !important;
}
.listItem{
    display: flex !important;
    gap: 20px !important;
    align-items: center;
    width: 100% !important;
    height: 44px !important;
    padding: 2px 8px 2px 8px;
    border-radius: 8px;
    color: var(--text-color, rgb(26, 26, 26));
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
}

.svgIcon path{
    fill: black !important;
}
.listItemText span{
    color: rgb(26, 26, 26) !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    white-space: nowrap !important;
}
.navLink{
    text-decoration: none;
    width: 100%;
}
.listItem{
    padding: 4px 8px !important;
    margin-bottom: 10px;
}

.navLink:hover{
    background-color: #F2F8FF !important;
 }
 .listItem .active{
    background-color: #F2F8FF !important;
 }
 .listItem .active .listItemText span{
    background-color: #F2F8FF !important;
    color: rgb(0, 107, 255) !important
 }
 .listItem .active .svgIcon path{
    fill: rgb(0, 107, 255) !important
 }



 /* Login.css */
 .WelcomeHeading1{
    font-size: 4.25rem;
    line-height: 1.2;
    font-weight: 700;
    white-space: pre-line;
    text-align: left;
    color: rgb(11, 53, 88);
    margin-top: 0 !important;
 }
 .WelcomeHeading1Span{
    color: rgb(0, 107, 255) !important;
 }
 .welcomeTextBlock{
    width: 100%;
    max-width: 770px;
    height: 100%;
    text-align: left;
    color: rgb(71, 103, 136);
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: normal;
 }
 .formLabel{
    margin-bottom: 8px !important;
    text-align: left !important;
    color: rgb(11, 53, 88) !important;
    font-size: 1.125rem !important;
    line-height: 1.6 !important;
    font-weight: 700 !important;
 }
 .inputField{
    border: 1px solid rgb(166, 187, 209);
    padding-right: 32px;
    appearance: none;
    box-shadow: rgba(10, 37, 64, 0.05) 0px 1px 2px;
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    padding: 8px 16px;
    font-weight: 500;
    font-size: 1.125rem;
 }
 .inputField:focus{
    box-shadow: rgba(0, 107, 255, 0.24) 0px 0px 0px 3px !important;
    border: 1px solid rgb(0, 107, 255) !important;
 }
 .inputField::placeholder{
    color: rgb(166, 187, 209)
 }
 .formBlock{
    position: relative;
    display: flex;
    width: 100%;
    gap: 10px;
 }
 .formBlock button{
    cursor: pointer;
    transition: all 0.2s linear 0s, background-position 1ms ease 0s, background-size 1ms ease 0s;
    color: rgb(255, 255, 255);
    border: 1px solid rgb(0, 107, 255);
    background-color: rgb(0, 107, 255);
    padding: 10px 18px;
    font-size: 1.125rem;
    position: relative;
    height: fit-content;
    flex-direction: row;
    display: inline-flex;
    opacity: 1;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize !important;
    width: max-content;
 }
 .formBlock button:hover{
    background-color: rgb(0, 107, 255);
    color: rgb(255, 255, 255);
 }
 .accountText{
    color: rgb(71, 103, 136);
font-weight: 700;
font-size: 20px;
 }
 .accountTextSpan{
    color: rgb(0, 107, 255);
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
 }
 .errorMsg{
    font-weight: 600;
    font-size: 0.75rem;
    left: 0px;
    bottom: -23px;
    color: rgb(255, 79, 0);
 }

 /* signup css */
 .accountDescription{
    font-size: 1.5rem !important;
    line-height: 1.4;
    margin-bottom: 24px;
    text-align: left;
    color: rgb(11, 53, 88) !important;
    font-weight: 700 !important;
 }
 .checkText{
    font-size: 1rem !important;
    line-height: 1.6;
    font-weight: 500;
 }
 .imgCheck{
    display: flex;
 }
 .signUpText{
    font-size: 1.75rem;
    line-height: 1.4;
    font-weight: 700;
    text-align: left;
    color: rgb(11, 53, 88);
 }
 .signUpForm{
    display: flex;
    z-index: 1;
    flex-direction: column !important;
    width: 100%;
    max-width: 588px;
    height: max-content;
    padding: 32px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(45, 40, 77, 0.1) 0px 12px 32px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 8px;
 }
 .signupDes{
    text-align: left;
    color: rgb(71, 103, 136);
    font-size: 1.125rem;
    line-height: 1.6;
    font-weight: 400;
    margin-bottom: 32px;
 }
 .SignUpInputField{
    width: 100%;
    border-radius: 4px;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    padding: 8px 16px;
    font-weight: 500;
    font-size: 1.125rem;
    border: 1px solid rgb(231, 237, 246);
    box-shadow: rgba(10, 37, 64, 0.05) 0px 1px 2px;
    height: 50px !important;
    margin-bottom: 24px;
 }
 .SignUpInputField::placeholder{
    color: rgb(166, 187, 209) !important
 }
 .signUpBtn{
    cursor: pointer;
    transition: all 0.2s linear 0s, 
    background-position 1ms ease 0s, 
    background-size 1ms ease 0s !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(0, 107, 255) !important;
    background-color: rgb(0, 107, 255) !important;
    padding: 10px 18px !important;
    font-size: 1.125rem !important;
    line-height: 1.6;
    position: relative !important;
    height: fit-content;
    opacity: 1;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    text-align: center;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    width: 100% !important;
 }
 .signUpPara{
    width: 100%;
    height: fit-content;
    padding-bottom: 4px;
    color: rgb(71, 103, 136);
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 500;

 }
.loginBtn{
    cursor: pointer;
    border: 1px solid rgb(248, 249, 251);
    background-color: rgb(248, 249, 251);
    padding: 10px 16px;
    font-size: 0.875rem;
    line-height: 1.4;
     gap: 8px;
    border-radius: 8px;
    text-align: center;
    font-weight: 600;
}
.loginBtn a{
    color: rgb(25, 72, 88);
}

/*  */

.welcomeText{
    color: black;
    font-size: 20px !important;
    font-weight: 700 !important;
}
.googleLoginBlock{
    min-width: 0;
    border: 1px solid #dadada;
    border-radius: 6px;
    background: var(--container-background-color, #ffffff);
    box-shadow: 0 1px 5px rgb(0 74 116 / 15%);
    box-sizing: border-box;
    width: 440px !important;
    margin: 24px auto !important;
    padding: 32px !important;
}
.normalLoginBlock{
    min-width: 0;
    border: 1px solid #dadada;
    border-radius: 6px;
    background: var(--container-background-color, #ffffff);
    box-shadow: 0 1px 5px rgb(0 74 116 / 15%);
    box-sizing: border-box;
    width: 550px;
    padding: 48px 22px;
    margin: 24px auto !important;
}
.googleLoginBtn{
    border-color: var(--primary-color, rgb(0, 105, 255)) !important;
    background-color: var(--primary-color, rgb(0, 105, 255)) !important;
    color: var(--text-color-secondary-4, rgba(255, 255, 255, 1)) !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    position: relative;
    display: inline-flex !important;
    vertical-align: middle !important;
    justify-content: center !important;
    align-items: center !important;
    box-sizing: border-box !important;
    min-height: 44px !important;
    padding: 8px 16px !important;
    border: 1px solid transparent !important;
    border-radius: 40px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    gap: 8px !important;
}
.googleLoginBtn .googleIcon{
   font-size: 16px;
   padding-bottom: 3px !important;
}
.microsoftloginbtn{
    border-color: var(--primary-color, rgb(11, 53, 88)) !important;
    background-color: var(--primary-color, rgb(11, 53, 88)) !important;
    color: var(--text-color-secondary-4, rgba(255, 255, 255, 1)) !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    position: relative;
    display: inline-flex !important;
    vertical-align: middle !important;
    justify-content: center !important;
    align-items: center !important;
    box-sizing: border-box !important;
    min-height: 44px !important;
    padding: 8px 16px !important;
    border: 1px solid transparent !important;
    border-radius: 40px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    gap: 8px !important;
}
.microsoftloginbtn .googleIcon{
   font-size: 16px;
   padding-bottom: 3px !important;
}
.dontAccountText{
    font-size: 14px;
    text-align: center;
}
.dontAccountText .span{
    color: rgb(0, 105, 255);
    padding-left: 3px;
}
.normalLabelText{
    gap: 4px;
    min-height: 22px;
    margin-bottom: 8px;
    color: var(--text-color, rgb(26, 26, 26));
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
}
.normalInputField{
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid var(--color-grey-2, #B2B2B2);
    border-radius: 8px;
    background: var(--color-bg-white-l-1, rgba(255, 255, 255, 1));
    color: #1a1a1a;
    overflow-wrap: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-height: 46px;
    padding: 10px 14px;
    font-size: 16px;
    line-height: 1.5;
}
.signUpBtnText{
    cursor: pointer;
    transition: all 0.2s linear 0s, 
    background-position 1ms ease 0s, 
    background-size 1ms ease 0s !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(0, 107, 255) !important;
    background-color: rgb(0, 107, 255) !important;
    padding: 8px 16px !important;
    font-size: 1rem !important;
    line-height: 1.6;
    position: relative !important;
    height: fit-content;
    opacity: 1;
    align-items: center;
    gap: 8px;
    border-radius: 40px !important;
    text-align: center;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    width: 100% !important;
}

.normalLoginBtn{
    border:1px solid rgb(26, 26, 26) !important;
    color:  rgb(26, 26, 26) !important;   
    height: 44px !important;
    padding: 8px 16px !important;
    border-radius: 40px !important;
    font-size: 16px !important;
    line-height: 20px !important;
    width: 100% !important;
    margin-bottom: 20px !important;
    text-transform: capitalize !important;
}

/* Inner Header */
.profileButton{
    border: 1px solid rgb(0, 105, 255) !important;
    color:  rgb(0, 105, 255) !important;
    height: 44px !important;
    padding: 8px 16px !important;
    border-radius: 40px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    font-weight: 700 !important;
}
.profileLetter{
    width: 40px !important;
    height: 40px !important;
    font-size: 14px;
    color: black;
    background-color: #CCCCCC;
    border: 2px solid #CCCCCC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popover{
    box-sizing: border-box;
    padding: 8px 0;
    border: 1px solid  #CCCCCC;
    border-radius: 6px;
    /* background: rgba(255, 255, 255, 1); */
    box-shadow: 0 1px 5px rgba(0, 74, 16, .15);
    color: rgb(26, 26, 26);
    font-size: 16px;
    width: 280px;
}
.profileName{
    font-size: 20px !important;
    font-weight: 700 !important;
    padding: 8px 16px;
}
.NotificationIcon{
    width: 16px !important;
    height: 16px !important;
}
.iconText{
    color: rgb(26, 26, 26) !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    cursor: pointer;
}
.accountHeading{
    color: rgba(26, 26, 26, 0.61) !important;
    font-size: 16px !important;
    height: 17px !important;
    font-weight: 700 !important;
    padding: 8px 16px;
}
.innerHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 12px 0;   
}
.layoutPage{
    background: linear-gradient(0deg, rgb(250 250 250 / 0%) 0%, rgb(250 250 250 / 100%) 16%);
    height: 100vh;
}
/* Events */
.pageTitle{
    font-weight: 700 !important;
    color: rgb(26, 26, 26);
    margin: 0 !important;
    padding: 16px 0 !important;
}
.searchTextField{
    border-radius: 8px !important;
    background-color: white !important;
}
.searchTextField fieldset{
    border-radius: 8px !important;
}
.profileText{
    color: rgb(26, 26, 26) !important;
    font-size: 14px !important;
}
.profileLink{
    color:  rgb(0, 105, 255) !important;
    font-size: 14px !important;
    cursor: pointer;
}
.createEventBtn{
    border:1px solid rgb(26, 26, 26) !important;
    color:  rgb(26, 26, 26) !important;
    height: 32px !important;
    padding: 16px 20px !important;
    border-radius: 40px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    height: 45px !important;
}
.addIcon{
    width: 16px !important;
    height: 16px !important;
    color:  rgb(26, 26, 26) !important;
}
.settingsIcon{
    width: 16px !important;
    height: 16px !important;
    color:  rgb(84 84 84) !important;
    stroke-width: .8;
}
.settingsIcon svg path {
    fill: rgb(26, 26, 26) !important;
    stroke-width: .8 !important; 
}
.profileHeaderBlock{
    /* padding-bottom: 20px !important;
    padding-top: 30px !important; */
    border-bottom: 1px solid #CCCCCC !important;
}
.meetingText{
    font-size: 20px !important;
    color: rgb(26, 26, 26) !important;
}

.linkPage p{
    padding-top: 20px !important;
    color: rgb(0, 105, 255) !important;
   
    line-height:3px !important;
    cursor: pointer;
    font-size: 16px !important;
}
.shareBtn{
    border:1px solid rgb(0, 105, 255) !important;
    color:  rgb(0, 105, 255) !important;
    height: 32px !important;
    padding: 4px 12px !important;
    border-radius: 40px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
}
.copyText{
    color:  rgb(0, 105, 255) !important;
    font-size: 14px !important;
    display: flex;
    align-items: center;
    gap: 4px
}
.copyText1{
    font-size: 14px !important;
    gap: 10px;
    display: flex;
    align-items: center;
}
.copyText1 svg {
    font-size: 20px !important;
}
.copyText svg{
   width: 20px !important;
   height: 20px !important;
}
.cardContent{
    padding-bottom: 30px !important;
    border-bottom: 1px solid #CCCCCC;
}
.cardBlock{
    border: 1px solid  #CCCCCC !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 15%) !important;
    /* border-top: 6px solid rgb(130, 71, 245) !important; */
}
/* Create Event */

.eventType{
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: bold !important;
}
.eventDescription{
    font-size: 16px !important;
    font-weight: bold !important;
    padding: 10px 0 !important;
}
.eventDescription1{
    color: rgba(26, 26, 26, 0.61) !important;
    font-size: 14px !important;

}
.newEventCard{
    box-shadow: 1px #CCCCCC inset !important;
    border-radius: 8px !important;
    background-color: #ffffff !important;
    border: 1px solid #CCCCCC;
    padding: 27px 0 !important;
    cursor: pointer;
}
.eventCard{
    transition: transform 0.3s ease;
    cursor: pointer;
}
.eventCard:hover {
    transform: translateY(-10px) scale(1);
  }


/*  */
.createEventSidenav{
    background-color: white !important;
    border-right: 1px solid  #CCCCCC !important;
    box-shadow: 0 1px 6px rgb(0 0 0 / 10%) !important;
    height: 100vh;
}
.createEventSidenavHeader{
    border-bottom: 1px solid #CCCCCC;
    padding:16px 24px !important;
}
.newEventType{
    font-size: 24px !important;
    font-weight: bold !important;
    padding-top: 20px !important;
}
.eventTextField{
    padding-top: 10px !important;
    
}
.eventTextField div{
    height: 45px !important;
    border-radius: 10px !important;
}
.labelName{
    color: rgb(26, 26, 26) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
}
.eventSelectField{
    height: 45px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
}
.formControl{
    padding-top: 20px !important;
}
.continueBtn{
    background-color:  rgb(0, 105, 255) !important;
    border: 1px solid rgb(0, 105, 255) !important;
    color: white !important;
    font-weight: 700 !important;
    /* margin-left: 20px !important; */
    text-transform: capitalize !important;
    border-radius: 40px !important;
    padding: 10px 20px !important;
    white-space: nowrap !important;

}
.createCard{
    position: relative !important;
    border-radius: 8px !important;
    background-color:  #ffffff !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}
.cardHeader{
    color:  #ffffff !important;
    font-size: 14px;
    line-height: 14px; 
    height: 36px;
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;
    background: #333;
}
.cardHeader .cardHeaderSpan{
font-weight: 700 !important;
}
.cardBodyProfileName{
    color:  rgba(26, 26, 26, 0.61) !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}
.eventName{
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 32px !important;
    padding-top: 10px !important;
}
.timeIcon{
    color:  rgba(26, 26, 26, 0.61) !important;
    font-weight: 700 !important;
}
.timeText{
    color:  rgba(26, 26, 26, 0.61) !important;
    font-weight: 700 !important;
}
.timeText1{
    font-size: 20px !important;
}
.cardBodyHalf{
    border-right: 1px solid #CCCCCC !important;
    height: 100% !important;
}
.previewText{
    color:  rgba(26, 26, 26, 0.61) !important;
    font-weight: 700 !important;
    font-size: 20px !important;
}
.linkProfileName{
    color:  rgba(26, 26, 26, 0.61) !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding-top: 20px !important;
}
.linkProfileText{
    padding: 0 20px !important;
    color:  rgba(26, 26, 26, 0.61) !important;
    font-size: 14px !important;
    padding-top: 20px !important;
}
.linkProfileHeader{
    max-width: 320px !important;
    margin: 0 auto 20px !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
}
.bullet{
    /* background-color: rgb(130, 71, 245); */
    width: 30px;
    height: 30px;
    border-radius: 100px;
}
.color1{
    background-color: rgb(255, 79, 0);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color2{
    background-color: rgb(255, 117, 142);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color3{
    background-color: rgb(229, 92, 255);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color4{
    background-color: rgb(130, 71, 245);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color5{
    background-color: rgb(0, 153, 255);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color6{
    background-color: rgb(10, 232, 240);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color7{
    background-color: rgb(23, 232, 133);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color8{
    background-color: rgb(204, 240, 0);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color9{
    background-color: rgb(248, 228, 54);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.color10{
    background-color: rgb(255, 166, 0);
    width: 20px;
    height: 20px;
    border-radius: 100px; 
}
.linkProfileEvent{
    font-weight: 700 !important;
    font-size: 18px !important;
}
.cardBox{
    height: 180px !important;
    cursor: pointer !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.cardBox:hover{
    background-color: #f3f3f3;
}
.brnadingBlock{
    position: absolute;
    right: -66px;
    top: 18px;
    z-index: 1;
    display: block;
    width: 226px;
    padding: 9px 0 6px;
    background-color: #505960;
    text-align: center;
    color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    pointer-events: auto;
    transition: all .1s ease-in;
    transform: rotate(45deg);
    cursor: pointer;
}

 .overflowBranding{
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 1;
    overflow: hidden;
    width: 145px;
    height: 150px;
    text-decoration: none;
    pointer-events: none;
    transition: all .1s ease-in;
    will-change: transform;
 }

/* Event Slot */
.eventSlotHeader{
    position: relative;
    z-index: 10;
    height: 10%;
    padding: 6px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
}
.menuText{
    color: black !important;
    font-size: 16px !important;
    text-transform: capitalize !important;
}

.popoverText{
    cursor: pointer !important;
}
.popoverText:hover{
    background:  #F2F8FF !important;
}
.timeBtn{
    width: 100% !important;
    height: 52px !important;
    border: 1px solid  rgba(0, 105, 255, 0.5) !important;
    color:  rgb(0, 105, 255) !important;
    padding: 13px 10px !important;
    border-radius: 4px !important;
    font-weight: 700 !important;
    text-transform:lowercase !important;
    
}
.copiedText{
    display: flex;
    align-items: center;
}
.copiedText svg {
    width: 20px !important;
    height: 20px !important;
}
.iconButonSvg{
    width: 20px !important;
    height: 20px !important;
    margin-right: 20px !important;
}
.timeBtn1{
   background-color: rgb(0 0 0 / 60%) !important;
    width: 100% !important;
    height: 52px !important;
    color:  white!important;
    padding: 13px 10px !important;
    border-radius: 4px !important;
    font-weight: 700 !important;
    text-transform: lowercase !important;
}
.timeNxtBtn{
    background-color:  rgb(0, 105, 255) !important;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%) !important;
    width: 100% !important;
    height: 52px !important;
    color:  white!important;
    padding: 13px 10px !important;
    border-radius: 4px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important; 
}
@keyframes slideInLeft {
    from {
      transform: translateX(40%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
}
  @keyframes slideOutRight {
    from {
      transform: translateX(-40%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-in {
    animation: slideInLeft 0.5s ease-out;
    overflow: hidden;
  }
  .slide-out-right {
    animation: slideOutRight 0.5s ease-out;
  }
  .backSpaceIcon{
    color: rgb(0, 105, 255) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background-clip: padding-box;
    font-size: 24px;
    background-color: rgba(0, 105, 255, 0.15) !important;
    margin-bottom: 30px !important;
  }
  /* .Mui-selected{
   background-color: rgb(0, 105, 255) !important;
  } */
  .timeBlock{
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
    padding: 10px 40px 10px 0px;
  }
  .MuiDateCalendar-root{
    width: 100% !important;
  }
  .MuiPickersDay-root{
    font-size: 16px !important;
    width: 40px !important;
    height: 40px !important;
  }
  .MuiDayCalendar-weekDayLabel{
    font-size: 16px !important;
  }
  .MuiDayCalendar-weekContainer {
    margin-top: 20px !important;
  }
  .MuiDayCalendar-slideTransition{
    min-height: 305px !important;
  }
  /* Event Details */
  .eventDetailsHeading{
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 20px !important;
    line-height: 1.2;
    color: black !important;
    font-weight: 700 !important;

  }
  .textArea{
    width: 94% !important;
    height: 100px !important;
    border: 1px solid #B2B2B2 !important;
    padding: 13px 10px !important;
    text-transform: capitalize !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
  }
  .event1{
    border-radius: 0 0 10px 10px !important;
    padding-top: 10px !important;
  }
  .event1 div{
    height: 45px !important;
  }
  .scheduledText{
    color:  rgb(26, 26, 26) !important;
    font-size: 14px !important;
    padding-top: 20px !important;
  }
  .scheduledHeading{
    font-size: 24px !important;
    color: rgb(26, 26, 26) !important;
    font-weight: 700 !important;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  .checkCircleIcon{
    color:  #038164 !important;
  }
  .scheduledEventName{
    color: rgb(26, 26, 26) !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-transform: capitalize !important;
  }
  .scheduledBox{
    margin-top: 15px !important;
    padding: 20px;
    border: 1px solid #CCCCCC !important;
    border-radius: 8px;
  }
  .scheduleTabs{
    color: #0060e6 !important;
    font-weight: bold !important;
  }
  .profilePictureBlock{
    width: 90px;
    height: 90px;
    flex-shrink: 0;
  }
  .profilePictureBlock img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
  .deleteBtn{
    border:1px solid #C84545 !important;
    background-color: #C84545 !important;
    color: rgba(255, 255, 255, 1) !important;
    font-weight: 700 !important;
    padding: 10px 30px !important;
    border-radius: 40px !important;
    text-transform: capitalize !important;
  }


  /* Scheduled Events */

  .tabsBlock{
    border: 1px solid #CCCCCC !important;
    border-radius: 6px !important;
    background-color:  rgba(255, 255, 255, 1) !important;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 5%) !important;
  }
  .accordianBlock{
    box-shadow: none !important;
  }
  .dateBlock{
    padding: 16px 24px !important;
    border-bottom: 1px solid  #CCCCCC !important;
    background-color:  #FAFAFA !important;
   
  }
  .tabsText{
    font-size: 16px;
    color: rgb(26, 26, 26) !important;
    font-weight: bold !important;
  }
  .tabPanelBlock{
    padding: 0 !important;
    border-bottom: 1px solid #CCCCCC !important;
  }
  .eventTabBlock{
    padding: 24px !important;
  }

  .eventShareText{
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: bold !important;
  }
  .eventStatusText{
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: bold !important;
    color: rgba(26,26,26,0.61) !important;
    
  }
  .reachedText{
    font-size: 16px !important;
    color: rgba(26,26,26,0.61) !important;
    font-weight: bold !important;
    text-align: center;
    padding: 20px 0px;
  }
  .meetIcon{
    width: 20px;
    height: 20px;
  }
  .shareLinkText{
    font-size: 18px !important;
    font-weight: bold !important;
  }
  .copyLinkBlock{
    flex: 1;
    height: 46px;
    border: 1px solid  #FAFAFA !important;
    border-right: none;
    border-radius: 46px !important;
    background-color:  #F2F2F2 !important;
    color:  rgb(26, 26, 26) !important;
    padding: 15px 20px !important;
    font-size: 13px !important;
  }
  .copyLinkBtn{
    border-color:  rgb(0, 105, 255) !important;
    background-color: rgb(0, 105, 255) !important;
    color:  rgba(255, 255, 255, 1) !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    text-transform: capitalize !important;
    border-radius: 40px !important;
    padding: 10px 20px !important;
    position: absolute !important;
    right: 0rem;
    top: 2.5rem;
  }
  .eventBorder{
    border-bottom: 1px solid #E6E6E6 !important;
    cursor: pointer !important;
  }
  .popoverEvent{
    width: 200px !important;
    top: 0px !important;
    left: -120px !important;

  }
  .popoverEvent div{
    width: 100% !important;
  }


  /* Edit Events */
  .dateLabel{
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  .CalendarInfoText{
    font-size: 14px !important;
    color: grey !important;
  }
  .editEventDetails{
    animation: animateIn 0.2s ease-out;

  }
  @keyframes animateIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.availableDescription{
    font-size: 14px !important;
    color:  rgba(26, 26, 26, 0.61) !important;
    font-weight: 400 !important;
    padding-top: 10px !important;
}
.autoCompleteField div{
    padding-right: 0 !important;
}
.weekDays{
    color:  rgb(26, 26, 26) !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    text-transform: uppercase !important;
}
.eventBlockAlign{
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}
.successAlert.MuiSnackbar-root{
    background: #038164 !important;
    color: white !important;
    font-weight: 600 !important;
    box-shadow: 0 6px 9px -5px rgb(18 156 123/25%) !important;
}
.errorAlert.MuiSnackbar-root{
    background: #FF0000 !important;
    color: white !important;
    font-weight: 600 !important;
    box-shadow: 0 6px 9px -5px rgba(255, 0, 0, 0.25) !important;
    
}
.calendarBlock .MuiDateCalendar-root{
    max-height: 405px !important;
    height: 405px !important;
}
.rmdp-top-class {
    width: 388px !important;
}
/* .rmdp-left{
    pointer-events: none;

} */
/* .rmdp-left .rmdp-arrow{
    border:solid black !important;
    border-width: 0 2px 2px 0 !important;

} */

.rmdp-day-picker div{
    width: 350px !important;
}
.rmdp-week div{
    font-size: 16px !important;
    color: black !important;
}
.rmdp-day span {
    font-size: 16px !important;
    color: rgba(26, 26, 26, 0.61);
    position: absolute !important;
}
.rmdp-day, .rmdp-week-day{
    height: 50px !important;
}

.rmdp-header-values{
    font-size: 16px !important;
}
.rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
    background-color:  rgb(0, 105, 255) !important;
    color:  rgba(255, 255, 255, 1) !important;
}
.rmdp-shadow{
    box-shadow: none !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #0060e6 !important;
    box-shadow: 0 0 3px #8798ad;
    color: #fff;
    font-weight: bold !important;
}


.rmdp-today span.highlighted-date::after {
    content: ''; 
    position: absolute !important;
    bottom: 5px; 
    left: 50%; 
    transform: translateX(-50%);
    width: 5px; 
    height: 5px; 
    background-color: #0060e6 !important; 
    border-radius: 50%;
}
.rmdp-today.rmdp-selected span::after{
    content: ''; 
    position: absolute !important;
    bottom: 5px; 
    left: 50%; 
    transform: translateX(-50%);
    width: 5px; 
    height: 5px; 
    background-color: #ffffff !important; 
    border-radius: 50%; 
}


.MuiTooltip-tooltipPlacementTop{
    background-color: black !important;
}
.highlighted-date {
    background-color: rgba(0, 105, 255, 0.065) !important;
    color: #0060e6 !important;
    font-weight: 700 !important;
    box-shadow: none !important;
}
.rmdp-selected .highlighted-date{
    background-color: rgb(0, 105, 255) !important;
    color: rgba(255, 255, 255, 1) !important;
    border-radius: 50% !important;
    font-weight: 700 !important;
}
.containerAdjust{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.MuiTabs-indicator{
    background-color: #0060e6 !important;
}
.logoImg{
    display: flex !important;
justify-content: center !important;
}
.privacytext{
    color: rgb(71, 103, 136);
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: normal;
}
.privacyheading{
    color:rgb(11, 53, 88) !important;
    font-size: 18px !important;
    font-weight: 600 !important;
}
.privacymainheading{
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 700;
    padding-bottom: 24px;
    white-space: pre-line;
    text-align: center;
    color: rgb(11, 53, 88);
    margin: 0 !important;
}